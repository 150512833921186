<template>
  <div class="content-wrap">
    <div class="create-header">
      <div class="header-top">
        <span>{{ title }}</span>
        <div></div>
      </div>
      <div class="header-bottom">
        <el-form ref="reimburseForm" :model="formInfo" :rules="rules" label-width="140px">
          <el-row type="flex" justify="space-between">
            <el-col :span="11">
              <el-form-item label="提报时间" prop="created" placeholder="请输入开票时间">
                <el-date-picker
                    v-model="formInfo.created"
                    type="date"
                    :disabled="ifShow"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerDisabled"
                    placeholder="请选择时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="提报人" prop="creatorName">
                <el-input :disabled="ifShow" v-model="formInfo.creatorName" placeholder="请输入提报人" maxlength="6"/>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="价税合计" prop="sumAmount">
                <el-input :disabled="ifShow" v-model="formInfo.sumAmount" placeholder="请输入价税合计" maxlength="6"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between">
            <el-col :span="11">
              <el-form-item label="报销码" prop="batchNo">
                <el-input :disabled="ifShow" v-model="formInfo.batchNo" placeholder="请输入报销码" maxlength="6"/>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="凭证编号" prop="voucherNo">
                <el-input :disabled="ifShow" v-model="formInfo.voucherNo" placeholder="请输入凭证编号" maxlength="6"/>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="报销主体" prop="buyerName">
                <el-input :disabled="ifShow" v-model="formInfo.buyerName" placeholder="请输入报销主体" maxlength="6"/>
              </el-form-item>
            </el-col>
          </el-row>         
          <el-row type="flex" justify="space-between">
            <el-col :span="11">
              <el-form-item label="报销描述" prop="remark">
                <textarea :disabled="ifShow" v-model="formInfo.remark" style="width: 560px;"></textarea>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>

    <div class="create-main">
      <div class="header-top">
        <span>发票明细</span>
      </div>
      
      <div class="ygx_total_box" v-loading="ygxLoading">
          <span
            >总发票数：<label>{{ ygxSummary.total }}</label
            >&nbsp;张</span
          >
          <span
            >总发票税额：<label
              >￥{{ formatMoney(ygxSummary.taxAmount) }}</label
            ></span
          >
          <span
            >总有效税额：<label
              >￥{{ formatMoney(ygxSummary.deductTaxAmount) }}</label
            ></span
          >
          <span
            >总发票金额：<label
              >￥{{ formatMoney(ygxSummary.amount) }}</label
            ></span
          >
        <el-button
          type="text"
          @click="getSummaries"
          icon="el-icon-refresh"
          >刷新</el-button
        >
        <el-button
          type="text"
          @click="handleInvoiceImage"
          >影像</el-button
        >
      </div>

      <div class="content-table">
       <el-table ref="invoiceCenterTable" :data="tableData" stripe border v-loading="tableLoading" :header-cell-style="handleHeaderCellStyle" @selection-change="handleSelection" style="width: 100%">
          <el-table-column type="index" label="序号" width="70" />
          <el-table-column v-if="columns['operate'].visible" prop="operate" :label="columns['operate'].label" width="120" fixed="left" align="center">
            <template slot-scope="scope">
                <el-button
                  type="text"
                  :loading="scope.row.loading"
                  @click="handleInvoiceImage(scope.row)"
                  >影像</el-button 
                >
            </template>
          </el-table-column>
          <el-table-column v-if="columns['invoiceType'].visible" prop="invoiceType" :label="columns['invoiceType'].label" width="150" :formatter="handleTableValueFormat" />
          <el-table-column v-if="columns['rider'].visible" prop="rider" :label="columns['rider'].label" width="100" :formatter="handleTableValueFormat" />
          <el-table-column v-if="columns['invoiceCode'].visible" prop="invoiceCode" :label="columns['invoiceCode'].label" width="120" :formatter="handleTableValueFormat" />
          <el-table-column v-if="columns['invoiceNo'].visible" prop="invoiceNo" :label="columns['invoiceNo'].label" width="120" :formatter="handleTableValueFormat" />
          <el-table-column v-if="columns['eInvoiceNo'].visible" prop="eInvoiceNo" :label="columns['eInvoiceNo'].label" width="120" :formatter="handleTableValueFormat" />
          <el-table-column v-if="columns['train21No'].visible" prop="train21No" :label="columns['train21No'].label" width="180" :formatter="handleTableValueFormat" />
          <el-table-column v-if="columns['ticketPrice'].visible" prop="amount" :label="columns['ticketPrice'].label" width="100" :formatter="handleTableValueFormat" />
          <el-table-column v-if="columns['surcharge'].visible" prop="surcharge" :label="columns['surcharge'].label" width="100" :formatter="handleTableValueFormat" />
          <el-table-column v-if="columns['taxAmount'].visible" prop="taxAmount" :label="columns['taxAmount'].label" width="180" :formatter="handleTableValueFormat" />
          <el-table-column v-if="columns['amount'].visible" prop="amount" :label="columns['amount'].label" width="100" :formatter="handleTableValueFormat" />
          <el-table-column v-if="columns['taxRate'].visible" prop="taxRate" :label="columns['taxRate'].label" width="80" :formatter="handleTableValueFormat" />
          <el-table-column v-if="columns['invoiceTime'].visible" prop="invoiceTime" :label="columns['invoiceTime'].label" width="130" :formatter="handleTableValueFormat" />
          <el-table-column v-if="columns['receiveTime'].visible" prop="receiveTime" :label="columns['receiveTime'].label" width="180" />
          <el-table-column v-if="columns['batchNo'].visible" prop="batchNo" :label="columns['batchNo'].label" width="130" :formatter="handleTableValueFormat" />
          <el-table-column v-if="columns['imageType'].visible" prop="imageType" :label="columns['imageType'].label" width="80" :formatter="handleTableValueFormat" />
          <el-table-column v-if="columns['orgName'].visible" prop="orgName" :label="columns['orgName'].label" width="200" :formatter="handleTableValueFormat" />
          <el-table-column v-if="columns['creatorName'].visible" prop="creatorName" :label="columns['creatorName'].label" width="120" :formatter="handleTableValueFormat" />
          <el-table-column v-if="columns['projectName'].visible" prop="projectName" :label="columns['projectName'].label" width="120" :formatter="handleTableValueFormat" />
          <el-table-column v-if="columns['updated'].visible" prop="updated" :label="columns['updated'].label" width="160" :formatter="handleTableValueFormat" />
        </el-table>
      </div>

    </div>
    <div class="edit-select">
      <div class="edit-select-foot">
        <!-- <el-button class="el-button-ext" @click="handleCancel">关闭</el-button> -->
      </div>
    </div>
    <!-- 票据影像弹窗 -->
    <el-image-viewer v-if="invoiceImageViewer" :url-list="invoiceImages" :on-close="handleImageViewerClose" style="z-index: 2020" />
  </div>
</template>

<script>
import {tableStyle} from '@/util/mixins';
import RegexLib from '@/assets/js/regex-lib'
import { fmtMoney } from "@/assets/js/format-util";
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
import { searchInvoicesListByBatchNo, getInvoiceImagesByBatchUid, getInvoiceDetailsByBatchNo } from '@/service/invoice-reimburse';

export default {
  name: 'create',
  components: { ElImageViewer },
  mixins: [tableStyle],
  props: {
    lockObject: {
      type: Object,
      default: () => {
        return {}
      }
    },
    show: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      // default: false
    }

  },
  watch: {
    show () {
      this.visible = this.show
    },
    lockObject () {
      this.formInfo = this.lockObject
    }
  },
  data () {
    return {
      visible: true,
      pickerDisabled: {
        disabledDate (date) {
          return date.getTime() > Date.now()
        }
      },
      ygxLoading: false,
      ygxSummary: {
        total: 0,
        amount: 0,
        taxAmount: 0,
        deductTaxAmount: 0,
      },
      /* 票据影像数据区 */
      invoiceImageViewer: false,
      invoiceImages: [],
      tableData: [],
      title: '报销发票单',
      formInfo: this.lockObject,
      ifShow: true,
      tableLoading: false,
      contractFile: '',
      columns: {
        invoiceType: {
          label: '发票类型',
          visible: true
        },
        rider: {
          label: '乘车人',
          visible: true
        },
        invoiceCode: {
          label: '发票代码',
          visible: true
        },
        invoiceNo: {
          label: '发票号码',
          visible: true
        },
        train21No: {
          label: '电子客票号码/21位码',
          visible: true
        },
        ticketPrice: {
          label: '票价',
          visible: true
        },
        surcharge: {
          label: "燃油附加",
          visible: true,
        },
        taxAmount: {
          label: '税额',
          visible: true
        },
        amount: {
          label: '不含税金额',
          visible: true
        },
        taxRate: {
          label: '税率',
          visible: true
        },
        invoiceTime: {
          label: '开票日期',
          visible: true
        },
        receiveTime: {
          label: '收票时间',
          visible: true
        },
        batchNo: {
          label: '识别批次',
          visible: true
        },
        imageType: {
          label: '影像类型',
          visible: true
        },
        creatorName: {
          label: '上传者',
          visible: true
        },
        orgName: {
          label: '上传组织',
          visible: true
        },
        eInvoiceNo: {
          label: '数电发票号码',
          visible: true
        },
        projectName: {
          label: "项目名称",
          visible: true,
        },
        updated: {
          label: "更新时间",
          visible: true,
        },
        operate: {
          label: '操作',
          visible: true
        }
      },
      rules: {
        // name: [
          // { required: true, message: '请输入组织名称', trigger: 'blur' },
        //   { min: 1, max: 128, message: '长度在 1 到 128 个字符', trigger: 'blur' },
        //   { 'validator': this.handleCheckprojectName, trigger: 'blur' }
        // ],
      },
    }
  },
  created () {
    this.formInfo.batchNo = this.$route.query.batchNo;
    this.formInfo.batchUid = this.$route.query.batchUid;
    if (this.formInfo) {
      this.handleReimburseInvoice();
    }
  },
  computed: {
    ruless: function () {
      return this.rules
    }
  },
  methods: {
    async renderTable() {
      this.tableData = [];
      const {success, data} = await searchInvoicesListByBatchNo({
        reimburseId: this.formInfo.id,
        batchUid: this.formInfo.batchUid
      });
      if (success && data.length > 0) {
        this.tableData = data;
        this.getSummaries();
      }
    },
    // 表格合计
    getSummaries() {
      if (this.tableData) {
        this.ygxSummary.taxAmount = 0;
        this.ygxSummary.deductTaxAmount = 0;
        this.ygxSummary.amount = 0;
        this.ygxSummary.total = this.tableData.length;
        this.tableData.forEach((v) => {
          this.ygxSummary.taxAmount = this.ygxSummary.taxAmount + v.taxAmount;
          this.ygxSummary.deductTaxAmount = this.ygxSummary.deductTaxAmount + v.deductTaxAmount;
          this.ygxSummary.amount = this.ygxSummary.amount + v.amount;
        });
      }
    },
    formatMoney(value) {
      return fmtMoney(value);
    },
    /* 表格数据选中 */
    handleSelection(value) {
      this.selections = value;
    },
    /* 表格值格式化 */
    handleTableValueFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case '发票类型':
          return this.handleValueToLabel('InvoiceType', value);
        case '税额':
        case '票价':
        case '价税合计':
          if (value == null) return '--';
          return '￥' + value;
        case '不含税金额':
          //if (row.invoiceType == 'PlaneInvoice') {
          if (row.invoiceType == 'ElePlaneInvoice') {
            if (value == null) return '￥' + '0';
            return '￥' + ((value + Number(row.surcharge)) / (1 + parseFloat(row.taxRate))).toFixed(2);
          } else if (row.invoiceType == 'PassengerInvoice' || row.invoiceType == 'TrainInvoice') {
            if (value == null) return '￥' + '0';
            return '￥' + (value / (1 + parseFloat(row.taxRate))).toFixed(2);
          } else {
            return '--';
          }
        case '影像类型':
          if (row.invoiceFileId == 0 || row.invoiceFileId == null) return '--';
          return this.handleValueToLabel('InvoiceImageType', value);
        case '乘车人':
        case '发票代码':
        case '发票号码':
        case '识别批次':
        case '电子客票号码/21位码':
        case '上传组织':
        case '上传者':
          if (value) return value;
          return '--';
        case '开票日期':
          if (value != undefined) {
            return this.$moment(value).format('YYYY-MM-DD');
          }
          return '--';
        case '税率':
          let str = '';
          if (value) {
            str = Number(value * 100);
            str += '%';
            return str;
          } else {
            return '--';
          }
        default:
          return value;
      }
    },
    /* 报销发票基本信息 */
    handleReimburseInvoice() {
      getInvoiceDetailsByBatchNo(this.formInfo.batchNo, this.formInfo.batchUid).then((res) => {
        if (res.success) {
          this.formInfo.id = res.data.id;
          this.formInfo.created = res.data.created;
          this.formInfo.creatorName = res.data.creatorName;
          this.formInfo.buyerName = res.data.buyerName;
          this.formInfo.sumAmount = res.data.sumAmount;
          this.formInfo.batchNo = res.data.batchNo;
          this.formInfo.batchUid = res.data.batchUid;
          this.formInfo.voucherNo = res.data.voucherNo;
          this.formInfo.remark = res.data.remark;
          this.renderTable();
        }
      });
    },
    /* 发票影像 */
    handleInvoiceImage(invoice) {
      if (!invoice || !invoice.id) {
        invoice = this.tableData[0];
      }

      invoice.loading = true;
      getInvoiceImagesByBatchUid(invoice.batchUid, invoice.reimburseId, invoice.id).then((res) => {
        if (res.success) {
          this.invoiceImageViewer = true;
          this.invoiceImages = res.data;
        }
        invoice.loading = false;
      });
    },
    /* 发票影像弹窗关闭 */
    handleImageViewerClose() {
      this.invoiceImageViewer = false;
      this.invoiceImages = [];
    },
    // 点击取消的回调
    handleCancel() {
      this.$emit('handleDetailCancel');
    },
    // 
    handIsDisabled() {
      return this.isEdit;
    },
    /* 时间格式化 */
    handleDateFormat(date) {
      if (date == undefined) {
        return '';
      }
      return this.$moment(date).format('YYYY-MM-DD');
    },
    // 禁止输入特殊字符
    handleNoInputSpecialCharacter (e) {
      let flag = RegexLib.TaxNo
      e.target.value = e.target.value.replace(flag, '')
    },
    /* 发票修改取消 */
    handleformInfoCancel () {
      this.visible = false
      this.$emit('cancel')
    },
  }
}
</script>
<style lang="scss" scoped>
  .create-header {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 2px 8px 0px rgba(153, 153, 153, 0.1);

    .header-top {
      padding: 16px 24px;
      font-size: 16px;
      font-weight: 500;
      border-bottom: 1px solid #e9e9e9;
      color: #333;
      display: flex;

      div {
        width: 406px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #F5222D;
        line-height: 20px;
        margin-left: 16px;
      }

      span:before {
        content: "|";
        display: inline-block;
        font-size: 16px;
        margin-right: 10px;
        background-color: #333;
      }
    }

    .header-bottom {
      margin-bottom: 24px;

      .el-row {
        margin: 24px 0px;

        &:last-child {
          padding-bottom: 20px;
        }
      }

      label {
        width: 70px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 20px;
      }

      span {
        width: 56px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 20px;
      }
    }
  }

  .create-main {
    width: 100%;
    height: 365px;
    background-color: #fff;
    overflow-x: auto;
    margin-bottom: 24px;

    .header-top {
      padding: 16px 24px;
      font-size: 16px;
      font-weight: 500;
      border-bottom: 1px solid #e9e9e9;
      color: #333;
      display: flex;

      div {
        width: 406px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #F5222D;
        line-height: 20px;
        margin-left: 16px;
      }

      span:before {
        content: "|";
        display: inline-block;
        font-size: 16px;
        margin-right: 10px;
        background-color: #333;
      }
    }

    .license-title {
      height: 40px;
      margin: 24px;
      background: rgba(235, 244, 255, 1);
      display: flex;
      align-items: center;
      padding-left: 24px;

      img {
        width: 16px;
        height: 16px;
        margin-right: 3px;
      }

      span {
        width: 1082px;
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
      }
    }

    .ygx_total_box {
      display: flex;
      padding: 10px 16px;
      border-width: 1px;
      border-radius: 8px;
      border-color: #dddddd;
      border-style: solid;
      margin: 10px 1px 0px 1px;
      span {
        color: #333;
        font-size: 14px;
        margin-right: 38px;
        display: flex;
        align-items: center;
        label {
          font-weight: 800;
          font-size: 16px;
          color: red;
        }
      }
    }

    .content-table {
      display: flex;
      flex-direction: column;
      align-items: center;
      .el-button {
        width: 120px;
      }
    }

  }

  .header-top {
    padding: 16px 24px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid #e9e9e9;
    color: #333;

    span:before {
      content: "|";
      display: inline-block;
      font-size: 16px;
      margin-right: 10px;
      background-color: #333;
    }
  }

  .edit-select {
    height: 60px;
    text-align: center;
  }

  .el-input-number {
    width: 200px;
    
    .el-input__inner {
      text-align: right;
    }

  }
  
</style>
<style>
  .el-form-item__error {
    top: 30px;
  }
</style>


